<template>
  <div>
    <v-card-title
      class="align-start d-flex align-center flex-wrap pl-0"
      style="font-size: 16px;"
    >
      <v-avatar
        size="40"
        rounded
        :color="color"
        :class="`v-avatar-light-bg ${color}--text me-3`"
      >
        <v-img
          max-height="20"
          max-width="20"
          contain
          :src="require(`@/assets/images/pages/${img}.png`)"
        ></v-img>
      </v-avatar>
      <p class="d-flex align-center mb-0">
        {{ t(field) }}
      </p>
    </v-card-title>

    <!-- Cash -->
    <v-list
      v-if="summary.cash"
      class="pt-1"
    >
      <v-list-item class="d-flex px-0">
        <div class="d-flex align-center flex-grow-1 flex-wrap">
          <div class="me-2">
            <h4 class="font-weight-semibold text-no-wrap">
              Cash
            </h4>
            <span class="text-xs text-no-wrap">{{ t('Total collection by cash') }}</span>
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex align-center">
            <span class="text-base font-weight-semibold text--primary me-1">
              {{ summary.cash }}
            </span>
          </div>
        </div>
      </v-list-item>
    </v-list>

    <!-- Card -->
    <v-list
      v-if="summary.card"
      class="pt-1"
    >
      <v-list-item class="d-flex px-0">
        <div class="d-flex align-center flex-grow-1 flex-wrap">
          <div class="me-2">
            <h4 class="font-weight-semibold text-no-wrap">
              Card
            </h4>
            <span class="text-xs text-no-wrap">{{ t('Total collection by card') }}</span>
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex align-center">
            <span class="text-base font-weight-semibold text--primary me-1">
              {{ summary.card }}
            </span>
          </div>
        </div>
      </v-list-item>
    </v-list>

    <!-- BIBD -->
    <v-list
      v-if="summary.bibd_bank"
      class="pt-1"
    >
      <v-list-item class="d-flex px-0">
        <div class="d-flex align-center flex-grow-1 flex-wrap">
          <div class="me-2">
            <h4 class="font-weight-semibold text-no-wrap">
              BIBD
            </h4>
            <span class="text-xs text-no-wrap">{{ t('Total collection by BIBD') }}</span>
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex align-center">
            <span class="text-base font-weight-semibold text--primary me-1">
              {{ summary.bibd_bank }}
            </span>
          </div>
        </div>
      </v-list-item>
    </v-list>

    <!-- Baiduri -->
    <v-list
      v-if="summary.baiduri_bank"
      class="pt-1"
    >
      <v-list-item class="d-flex px-0">
        <div class="d-flex align-center flex-grow-1 flex-wrap">
          <div class="me-2">
            <h4 class="font-weight-semibold text-no-wrap">
              Baiduri
            </h4>
            <span class="text-xs text-no-wrap">{{ t('Total collection by Baiduri') }}</span>
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex align-center">
            <span class="text-base font-weight-semibold text--primary me-1">
              {{ summary.baiduri_bank }}
            </span>
          </div>
        </div>
      </v-list-item>
    </v-list>

    <!-- Cheque -->
    <v-list
      v-if="summary.cheque"
      class="pt-1"
    >
      <v-list-item class="d-flex px-0">
        <div class="d-flex align-center flex-grow-1 flex-wrap">
          <div class="me-2">
            <h4 class="font-weight-semibold text-no-wrap">
              Cheque
            </h4>
            <span class="text-xs text-no-wrap">{{ t('Total collection by cheque') }}</span>
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex align-center">
            <span class="text-base font-weight-semibold text--primary me-1">
              {{ summary.cheque }}
            </span>
          </div>
        </div>
      </v-list-item>
    </v-list>

    <v-divider v-if="summary.cheque"></v-divider>

    <!-- Total -->
    <v-list
      v-if="summary.total"
      class="pt-1"
      :class="{ 'mb-3': $vuetify.breakpoint.smAndUp }"
    >
      <v-list-item class="d-flex px-0">
        <div class="d-flex align-center flex-grow-1 flex-wrap">
          <div class="me-2">
            <h4 class="font-weight-semibold text-no-wrap">
              Total
            </h4>
            <span class="text-xs text-no-wrap">{{ t('Total overall collection') }}</span>
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex align-center">
            <span class="text-base font-weight-semibold text--primary me-1">
              {{ summary.total }}
            </span>
          </div>
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { inject } from '@vue/composition-api'

export default {
  props: {
    field: {
      type: String,
      required: true,
    },
    summary: {
      type: Object,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  setup() {
    const t = inject('t')

    return {
      t,
    }
  },
}
</script>
